import React from "react";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom'
import kkdiklogo from '../pages/media/kkdik-logo.png'
import logokare from '../pages/media/logo-kare.jpg'

export function Services() {
    return (
        <>
            <Helmet>
                <title>Services | Kkdik Services</title>
                <meta name="description" content="DGROUTE​ offers Turkey only representative services, Turkish SDS authoring, translation and compliance services." />
            </Helmet>

            <div className="row contaier-fluid m-0 p-0">
                <div style={{ backgroundColor: "#F5F8FA" }}>
                    <div className='container'>
                        <div className="row my-5">
                            <div className="col-lg-12 mb-5">
                                <div className='rounded text-black'>
                                    <div className="card card-bordered p-10" style={{ backgroundColor: "#fff" }}>
                                        <div className="card-header ">
                                            <div className="card-title text-black">
                                                <h1>Services</h1>
                                            </div>
                                        </div>
                                        <div className="card-body" style={{ fontSize: "1.3rem", textAlign: "justify" }}>

                                            <div className="d-flex justify-content-center m-2">
                                                <a
                                                    href="https://www.dgroute.com/en/kkdik.html"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        src={kkdiklogo}
                                                        title="KKDIK"
                                                        alt="KKDIK logo"
                                                    />
                                                </a>
                                            </div>

                                            <h2 className="text-black ">Turkish REACH Regulation</h2>
                                            <h3 className="text-black h2">Registration</h3>
                                            <p className="text-left">
                                                <a
                                                    href="https://www.dgroute.com/en/t_reach_only_representative_kkdik.html"
                                                    target="_blank"
                                                    className="text-primary"
                                                    rel="noreferrer">DGROUTE​</a> offers Turkey only representative services, Turkish SDS authoring, translation and compliance services for all chemical industry in Turkey and abroad. Many aspects of the Global Harmonized Systems (GHS) have already been implemented in Turkey for Turkey chemical industry.
                                                <br /><br />
                                                The GHS Regulation came into force when The Turkey Official Gazette published the GHS Regulations with the set transitional periods in December 2014. The full implementation of the regulation has been implemented as of June 1, 2016 for both hazardous substances and mixtures. An SDS (formerly known as MSDS) includes information such as the properties of each chemical; the physical, health, and environmental health hazards; protective measures; and safety precautions for handling, storing, and transporting the chemical.
                                            </p>

                                            <h2 className="text-black "><Link to="/">Turkey OR services</Link></h2>
                                            <p className="text-left" >
                                                DGROUTE offers Turkey Only Representative services consultation on issues relating to KKDİK (Turkish REACH), Pre-SIEF, SIEF& Consortia Management, SDS authoring solutions.
                                            </p>

                                            <div className="d-flex justify-content-center">
                                                <a
                                                    href="https://www.dgroute.com/en/kkdik.html"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        src={logokare}
                                                        title="Company logo"
                                                        alt="Company logo square"
                                                    />
                                                </a>
                                            </div>

                                            <p className="text-left text-primary h3" >
                                                <Link to="/contact">Do not hesitate to contact us for comprehensive information and offer about KKDIK Pre-Registration Service and Turkish REACH Regulations.</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}