import React from "react";
import kkdik_english from './kkdik_english.pdf';
import { Helmet } from "react-helmet";

export function Kkdik() {

    return (
        <>
            <Helmet>
                <title>KKDIK | Kkdik Services</title>
                <meta name="description" content="Contact us so that we can give you the best service." />
            </Helmet>
            <div className="container-fluid p-5 m-0 h-100">
                <object data={kkdik_english} type="application/pdf" width="100%" style={{height: "92vh"}}>
                    <h6>Your web browser doesn't have a PDF plugin.
                        Instead you can <a download={true} href={kkdik_english}>click here to download the PDF file.</a>
                    </h6>
                </object>
            </div>
        </>
    );
}
