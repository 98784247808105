import React from "react";
import { toAbsoluteUrl } from '../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

export function Tpage2() {
    return (
        <>
            <Helmet>
                <title>What is KKDIK OR</title>
                <meta name="description" content="Non-Turkish manufacturers are not permitted to submit KKDİK (Turkish REACH) registration dossiers to the Ministry of Environment (MoE)." />
            </Helmet>
            <div className="row contaier-fluid m-0 p-0">
                <div style={{ backgroundColor: "#F5F8FA" }}>
                    <div className='container'>
                        <div className="row my-5">
                            <div className="col-lg-12 mb-5">
                                <div className='rounded text-black'>
                                    <div className="card card-bordered p-10" style={{ backgroundColor: "#fff" }}>
                                        <div className="card-header ">
                                            <div className="card-title text-black">
                                                <h1>What is KKDIK OR</h1>
                                            </div>
                                        </div>
                                        <div className="card-body" style={{ fontSize: "1.3rem", textAlign: "justify" }}>
                                            <p className="text-left">
                                                Non-Turkish manufacturers are not permitted to submit <Link to="/kkdik">KKDİK (Turkish REACH)</Link> registration dossiers to the Ministry of Environment (MoE). However, KKDİK provides for a non-Turkish manufacturer to appoint an Only Representative in order to fulfill the registration obligations on their behalf. DGROUTE is a Only representative in Turkey.
                                                <br /><br />
                                                KKDIK requires all companies manufacturing or placing a substance on Turkish market in quantities greater than 1t/year to register that substance with the MoEU. For legal reasons, only companies with a legal entity in Turkey are allowed to submit a registration. However, non-TR companies may submit registration by appointing a TR-based Only Representative to register on their behalf, in which case their importers will be regarded as downstream users and do not need to do registrations.
                                            </p>

                                            <a
                                                href="https://www.dgroute.com/en/t_reach_only_representative_kkdik.html"
                                                target="_blank"
                                                className="h2 text-primary"
                                                rel="noreferrer">​Benefits of Appointing REACH Only Representative (OR)
                                            </a>

                                            <ul>
                                                <li>
                                                    <p>Relieve importers of their obligations to register and obtain continued market access in Turkey (many TR importers will try to avoid registrations by purchasing KKDIK registered chemicals);</p>
                                                </li>
                                                <li>
                                                    <p>Avoid dependence on a single importer and keep market access should one TR importer cease trading;</p>
                                                </li>
                                                <li>
                                                    <p>Gain advantages over other non-TR suppliers who do not appoint OR to register their substances;</p>
                                                </li>
                                            </ul>

                                            <p className="text-left" >
                                                Note: Importers will be exempt from KKDIK registration if their non-TR suppliers have registered. However, importers need to confirm with their suppliers’ Only Representative that they are included in the inventory of importers and their tonnage and uses are covered by the OR. This can be done by asking for KKDIK Certificate of Compliance and Tonnage Coverage Certificate from the only representative of their suppliers before they put chemicals on the TR market.
                                                <br /><br />
                                                The service provides the necessary legal presence and representation in Turkey including representation in MoE, technical management of KKDİK pre-registration and registration, and submission of information to the MoE to fulfill a company’s KKDİK obligations. Whether your company is entering Turkey market for the first time or your business is considering a change of OR, we can offer a comprehensive service tailored to your unique requirements.
                                            </p>

                                            <div className="d-flex justify-content-center m-2">
                                                <a
                                                    href="https://www.dgroute.com/en/kkdik.html"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        src={toAbsoluteUrl('/media/logos/kkdik-logo.png')}
                                                        title="KKDIK"
                                                        alt="KKDIK logo"
                                                    />
                                                </a>
                                            </div>

                                            <p className="text-left text-primary h3" >
                                                <Link to="/contact">Do not hesitate to contact us for comprehensive information and offer about Only Representative Turkey and Turkish REACH Regulations.</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}