import React from "react";
import { Link } from 'react-router-dom'
import logokare from './media/logo-kare.jpg'
import { Helmet } from "react-helmet";

export function About() {
    return (
        <>
            <Helmet>
                <title>About | Kkdik Services</title>
                <meta name="description" content="DGROUTE provides services in the field of Only Representative, chemicals and dangerous goods logistics with expert staff certified by government agencies." />
            </Helmet>

            <div className="row contaier-fluid m-0 p-0">
                <div style={{ backgroundColor: "#F5F8FA" }}>
                    <div className='container'>
                        <div className="row my-5">
                            <div className="col-lg-12 mb-5">
                                <div className='rounded text-black'>
                                    <div className="card card-bordered p-10" style={{ backgroundColor: "#fff" }}>
                                        <div className="card-header ">
                                            <div className="card-title text-black">
                                                <h1>About</h1>
                                            </div>
                                        </div>
                                        <div className="card-body" style={{ fontSize: "1.3rem", textAlign: "justify" }}>
                                            <div className="d-flex justify-content-center">
                                                <a
                                                    href="https://www.dgroute.com/en/kkdik.html"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        src={logokare}
                                                        title="Company logo"
                                                        alt="Company logo square"
                                                    />
                                                </a>
                                            </div>
                                            <p className="text-left">
                                                DGROUTE provides services in the field of <Link to="/services/only-representative-services">Only Representative</Link>, chemicals and dangerous goods logistics with expert staff certified by government agencies.

                                                DGROUTE provides service with its expert staff who Chemical Evaluation Specialist certificate. We provide Safety Data Sheet (MSDS/SDS) preparation and alignment services in 52 languages in compliance with current regulations.

                                                DGROUTE offers Only Representative services consultation on issues relating to KKDİK (Turkish REACH ), Pre-SIEF, SIEF& Consortia Management, SDS authoring solutions.

                                                Our company operates from its headquarters in the Scientific and Technological Research Council of Turkey campus. We carry out R&D studies in the fields of chemistry and dangerous goods logistics and develop software solutions.
                                            </p>

                                            <h2 className="text-black">Our Mission</h2>
                                            <p className="text-left" >
                                                DGROUTE is the reliable business partner of its customers with its service and software solutions in the field of chemical legislation management.
                                            </p>

                                            <h2 className="text-black">Our Vision</h2>
                                            <p className="text-left" >
                                                DGROUTE believes innovation and collaboration is an indispensable value. As a brand that develops customer-oriented approaches with its sustainable service quality, it is our target to be your solution partner.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}