import React from "react";
import { useRef } from "react";
import { Formik, Field, Form, FormikHelpers } from 'formik';
import axios from "axios"
// import { KTSVG } from '../../_metronic/helpers'
import * as Yup from 'yup';
import Swal from 'sweetalert2'

const SignupSchema = Yup.object().shape({
    Name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required!'),
    Email: Yup.string().email('Invalid email').required('Required!').max(50, 'Too Long!'),
    Subject: Yup.string()
        .min(2, 'Too Short!')
        .max(200, 'Too Long!')
        .required('Required!'),
    Message: Yup.string()
        .min(5, 'Too Short!')
        .max(1500, 'Too Long!')
        .required('Required!'),
});

export function ContactForm() {

    interface Values {
        Name: string;
        Email: string;
        Subject: string;
        Message: string;
    }
    const btnRef = useRef<HTMLButtonElement | null>(null);
    const onClick = () => {
    };

    function postData(values: any, resetForm: any) {
        axios.post('/Mail/MailPost', values)
            .then(response => {
                var res = JSON.parse(response.data);
                btnRef.current?.removeAttribute("data-kt-indicator");
                if (res.StatusCode === 1) {
                    resetForm();
                    Swal.fire({
                        heightAuto: false,
                        title: 'Success!',
                        text: res.StatusText,
                        icon: 'success',
                        confirmButtonText: 'OK',
                        confirmButtonColor: 'green'
                    })
                } else {
                    Swal.fire({
                        heightAuto: false,
                        title: 'Error!',
                        text: res.StatusText,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: 'red'
                    })
                }
            });
    }

    return (
        <Formik
            initialValues={{
                Name: '',
                Email: '',
                Subject: '',
                Message: '',

            }}

            validationSchema={SignupSchema}

            onSubmit={(
                values: Values,
                { setSubmitting, resetForm }: FormikHelpers<Values>,

            ) => {

                btnRef.current?.setAttribute('data-kt-indicator', 'on');
                postData(values, resetForm);
                setSubmitting(false);
            }}

            on

        >

            {({ errors, touched }) => (
                <Form>

                    <div>
                        <label className='form-label' htmlFor="name" style={{ color: "black" }}><b>Name</b></label>
                        {errors.Name && touched.Name ? (
                            <div style={{ color: "#ff0800" }}><b>{errors.Name}</b></div>
                        ) : null}
                        <Field className="form-control mb-4" id="Name" name="Name" placeholder="Name" />

                    </div>

                    <div>
                        <label className='form-label' htmlFor="email" style={{ color: "black" }}><b>Email</b></label>
                        {errors.Email && touched.Email ? <div style={{ color: "#ff0800" }}><b>{errors.Email}</b></div> : null}
                        <Field
                            id="Email"
                            name="Email"
                            placeholder="Email"
                            type="text"
                            onInvalid={() => console.log("working!")}
                            className="form-control mb-4"
                        />

                        <div>
                            <label className='form-label' htmlFor="subject" style={{ color: "black" }}><b>Subject</b></label>
                            {errors.Subject && touched.Subject ? (
                                <div style={{ color: "#ff0800" }}><b>{errors.Subject}</b></div>
                            ) : null}
                            <Field className="form-control mb-4" id="Subject" name="Subject" placeholder="Subject" />

                        </div>
                    </div>

                    <div>
                        <label className='form-label' htmlFor="Message" style={{ color: "black" }}><b>Message</b></label>
                        {errors.Message && touched.Message ? (
                            <div style={{ color: "#ff0800" }}><b>{errors.Message}</b></div>
                        ) : null}
                        <Field as='textarea' className="form-control" id="Message" name="Message" placeholder="Message" rows={5} />

                    </div>

                    <button ref={btnRef} onClick={onClick} type="submit" className="btn btn-primary me-10 my-3" id="kt_button_1" style={{ color: "#fff", }}>
                        <span className="indicator-label text-white"><b>Submit</b></span>
                        <span className="indicator-progress text-white"><b>Please wait...</b>
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    </button>

                </Form>
            )}

        </Formik>
    );
}