/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
// import { useLayout } from '../core'
import { Link } from 'react-router-dom'

const Footer: FC = () => {
  // const { classes } = useLayout()

  const dFlex = {
    backgroundColor: "#FF672B",
    display: "flex"
  }

  const dNone = {
    backgroundColor: "#FF672B",
    display: "none"
  }

  if (window.location.pathname === "/kkdik") {
    var style;
    style = dNone
  } else {
    style = dFlex
  }

  return (
    <div className='footer py-4  flex-lg-column' id='kt_footer' style={style}>
      {/* begin::Container */}
      <div
        className={"container d-flex flex-column flex-md-row align-items-center justify-content-between"}
      >
        {/* begin::Copyright */}
        <div className='text-dark order-2 order-md-1'>
          <span className='h4 mr-1 text-white fw-bold me-2' style={{ color: "#fff" }}><b>{new Date().getFullYear()} |</b></span>
          {/* <a href='#' className='text-gray-800 text-hover-primary'>
            Keenthemes
          </a> */}
          <a href='https://www.dgroute.com/' rel="noreferrer" target={"_blank"} className='text-hover-primary' style={{ color: "#fff" }}>
            <span className='h4 mb-1 text-white'>
              <b>DGRoute</b>
            </span>
          </a>
        </div>
        {/* end::Copyright */}

        {/* begin::Nav */}
        <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
          <li className='menu-item mx-5'>
            <Link className='menu-link pe-0 pe-2' to='/about' style={{ color: "#fff" }}>
              <span className='h4 text-white'>
                <b> About</b>
              </span>
            </Link>
          </li>
          <li className='menu-item mx-5'>
            <Link className='menu-link pe-0 pe-2' to='/contact' style={{ color: "#fff" }}>
              <span className='h4 text-white'>
                <b>Contact</b>
              </span>
            </Link>
          </li>
          {/* <li className='menu-item'> */}
          {/* <a href='#' className='menu-link pe-0'>
              Purchase
            </a> */}
          {/* </li> */}
        </ul>
        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export { Footer }
