import React from "react";
import { toAbsoluteUrl } from '../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

export function Spage4() {
    return (
        <>
            <Helmet>
                <title>Turkish SDS Authoring Service</title>
                <meta name="description" content="Turkey applies the SEA (Classification, Labelling, Packaging) Regulation, which is aligned with CLP (1272/2008 EC) and fully entered into force on 2016." />
            </Helmet>

            <div className="row contaier-fluid m-0 p-0">
                <div style={{ backgroundColor: "#F5F8FA" }}>
                    <div className='container'>
                        <div className="row my-5">
                            <div className="col-lg-12 mb-5">
                                <div className='rounded text-black'>
                                    <div className="card card-bordered p-10" style={{ backgroundColor: "#fff" }}>
                                        <div className="card-header ">
                                            <div className="card-title text-black">
                                                <h1>Turkish SDS Authoring Service</h1>
                                            </div>
                                        </div>
                                        <div className="card-body" style={{ fontSize: "1.3rem", textAlign: "justify" }}>
                                            <p className="text-left">
                                                <a
                                                    href="https://www.dgroute.com/en/safety_data_sheet_authoring_service.html"
                                                    target="_blank"
                                                    className="text-primary"
                                                    rel="noreferrer">Turkey applies the SEA (Classification, Labelling, Packaging) Regulation, which is aligned with CLP (1272/2008 EC) and fully entered into force on 2016. This Regulation is available in Turkish only.</a> Turkish SDS authoring service should comply with this legislation.
                                                <br /><br />
                                                Similar to the EU CLP regulation, this regulation requires manufacturers and importers to notify the classification and labelling of hazardous substances and mixtures to the Turkish Classification and Labelling Inventory (C&L Notification).
                                                <br /><br />
                                                Moreover, Turkey requires for SDSs to be:
                                            </p>
                                            <ul>
                                                <li>
                                                    <p>Authored in Turkish language and provided free of charge</p>
                                                </li>
                                                <li>
                                                    <p>Submitted to Republic Of Turkey Ministry Of Environment And Urbanization</p>
                                                </li>
                                                <li>
                                                    <p>Prepared by a certified SDS author in accordance with Turkish standards. The expert can be an employee of the company itself, who attended an eligible formation course and gained the appropriate certificate as Turkish SDS author or it could be an external collaborator who possesses the appropriate certification. This certificate is provided by the Turkish Standards Institution. SDS should include the contact details of the certified person who prepared the SDS. Certificate date and number of the preparer the SDS footer.</p>
                                                </li>
                                            </ul>
                                            <p className="text-left">
                                                Therefore it is important to underline that provides a SDS model based on the CLP Regulation translated in Turkish, which can be further customised through a series of optional, Turkish SDS-specific functionalities that can be installed on demand in order to better align it with the customer’s needs. These functionalities make it possible to specify all necessary information regarding the certified SDS preparer.
                                            </p>
                                            <ul>
                                                <li>
                                                    <p><b>Regulation on Safety Data Sheets Regarding Hazardous Substances and Mixtures published in the Official Journal numbered 29204 on December 13, 2014</b></p>
                                                </li>
                                                <li>
                                                    <p><b>Classification, Labelling and Packaging of Substances and Mixtures (SEA) Regulation published in the Official Journal numbered 28848 on December 11, 2013.</b></p>
                                                </li>
                                                <li>
                                                    <p><b>Regulation on Health and Safety Precautions When Working with Chemical Substances published in the Official Journal numbered 28733 on August 12, 2013</b></p>
                                                </li>
                                                <li>
                                                    <p><b>Regulation on Health and Safety Precautions When Working with Carcinogenic and Mutagenic Substances published in the Official Journal numbered 28730 on August 6, 2013</b></p>
                                                </li>
                                                <li>
                                                    <p><b>Regulation on Transportation of Dangerous Goods by Road published in the Official Journal numbered 28801 on October 24, 2013</b></p>
                                                </li>
                                                <li>
                                                    <p><b>Regulation (OG) 23.06.2017 – 30105</b></p>
                                                </li>
                                            </ul>

                                            <div className="d-flex justify-content-center m-2">
                                                <a
                                                    href="https://www.dgroute.com/en/kkdik.html"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        src={toAbsoluteUrl('/media/logos/kkdik-logo.png')}
                                                        title="KKDIK"
                                                        alt="KKDIK logo"
                                                    />
                                                </a>
                                            </div>

                                            <p className="text-left text-primary h3" >
                                                <Link to="/contact">Do not hesitate to contact us for comprehensive information and offer about KKDIK Pre-Registration Service and Turkish REACH Regulations.</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}