/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
// import clsx from 'clsx'
// import {
//   toAbsoluteUrl,
//   defaultMessages,
//   defaultUserInfos,
//   MessageModel,
//   UserInfoModel,
//   messageFromClient,
// } from '../../helpers'
// import { Formik, Field, Form, FormikHelpers } from 'formik';
// import axios from "axios"
// import { Contact } from "../../../app/pages/Contact"
import { ContactForm } from "../../../app/pages/components/ContactForm"
import { AdressCard } from "../../../app/pages/components/AdressCard"
// import { GoogleMap } from "../../../app/pages/GoogleMap"

type Props = {
  isDrawer?: boolean
}

const ChatInner: FC<Props> = () => {

  return (
    <div className='container'>
      <div className="row">
        <div className="col-12"><ContactForm /></div>
        <div className="col-12 mb-2" style={{ height: "70%" }}>
          <AdressCard
            head="Head Office"
            title='Route Software and Consultancy Industry and Trade Limited Company'
            body='TÜBİTAK MAM Gebze Campus Technology Development Zone Kadir Has Building No:135,
                  41400 Gebze, Kocaeli TURKEY'
            footer='+90 262 33 00 237' />
        </div>

        {/* <div className="col-12 mt-2" style={{ height: "70%" }}>
          <AdressCard
            head="EU Office"
            title='Chem Safety Ltd.'
            body='2 Leighfield House, Woodberry Down Estate, London, United Kingdom, N4 2TR'
            footer='+90 262 33 00 237' />
        </div> */}
      </div>
    </div >
  );
}

export { ChatInner }
