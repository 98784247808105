import 'react-app-polyfill/ie11';
import * as React from 'react';
import "bootstrap/dist/js/bootstrap.min.js"
import { toAbsoluteUrl } from '../../_metronic/helpers';
import { ContactForm } from "../pages/components/ContactForm"
import { AdressCard } from "../pages/components/AdressCard"
import { GoogleMap } from "../pages/components/GoogleMap"
import { Helmet } from 'react-helmet';
// import img from '../pages/media/img.png';

export function Contact() {
    return (
        <>
            <Helmet>
                <title>Contact | Kkdik Services</title>
                <meta name="description" content="Contact us so that we can give you the best service." />
            </Helmet>

            <div className='row container-fluid m-0 p-0'>
                <div style={{ backgroundColor: "#F5F8FA" }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <h1 className='my-10'>
                                    Contact Us
                                </h1>
                                <ContactForm />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-10 col-12 bg-light d-inline-flex">
                                <img src={toAbsoluteUrl("/media/icons/duotune/general/gen060.svg")} title='Community' alt="Community date" style={{ width: "100%", height: "auto" }} />
                            </div>
                        </div>

                        <div className="separator border-3 my-10"></div>

                        <div className="row mb-5">

                            <div className=" col-lg-4 col-md-6 col-sm-12 mb-5">
                                <AdressCard
                                    head="Head Office"
                                    title='Route Software and Consultancy Industry and Trade Limited Company'
                                    body='TÜBİTAK MAM Gebze Campus Technology Development Zone Kadir Has Building No:135, 41400 Gebze, Kocaeli TURKEY'
                                    footer='+90 262 33 00 237' />
                            </div>

                            {/* <div className=" mb-5 col-lg-3 col-md-6 col-sm-6">
                                <AdressCard
                                    head="EU Office"
                                    title='Chem Safety Ltd.'
                                    body='2 Leighfield House, Woodberry Down Estate, London, United Kingdom, N4 2TR'
                                    footer='+90 262 33 00 237' />
                            </div> */}

                            <div className="col-lg-8 col-md-6 col-sm-12">
                                <GoogleMap />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}