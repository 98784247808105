import React from "react";

export function GoogleMap() {
    return (
        <div className="card card-custom card-stretch shadow" style={{ backgroundColor: "#fff", height: "%90" }}>
            <div className='m-2'>
                <iframe title='Location' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17088.078426131553!2d29.455851731596162!3d40.78898167499619!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdad144897898ff77!2sRoute%20Yazilim%20ve%20Dan%C4%B1%C5%9Fmanl%C4%B1k%20Sanayi%20ve%20Ticaret%20Limited%20%C5%9Eirketi!5e0!3m2!1str!2str!4v1647183880305!5m2!1str!2str" width="600" height="298" style={{ border: "0", width: "100%" }}></iframe>
            </div>
        </div>
    );
}