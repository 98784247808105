import { FC } from 'react'

const Error404: FC = () => {
  return (
    <>
      <h1 className='fw-bolder fs-4x mb-10' style={{ color: "#fff" }}>Page Not Found</h1>

      <div className='fw-bold fs-3 mb-15' style={{ color: "#fff" }}>
        The page you looked not found!
      </div>
    </>
  )
}

export { Error404 }
