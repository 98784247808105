import React from 'react'
import { toAbsoluteUrl } from '../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

export function Tpage1() {

    return (
        <>
            <Helmet>
                <title>About KKDIK</title>
                <meta name="description" content="According to the Turkish REACH regulation, Companies are responsible for collecting information on the properties and uses of the substances they manufacture or import above one tonne a year." />
            </Helmet>

            <div className="row contaier-fluid m-0 p-0">
                <div style={{ backgroundColor: "#F5F8FA" }}>
                    <div className='container'>
                        <div className="row my-5">
                            <div className="col-lg-12 mb-5">
                                <div className='rounded text-black'>
                                    <div className="card card-bordered p-10" style={{ backgroundColor: "#fff" }}>
                                        <div className="card-header ">
                                            <div className="card-title text-black">
                                                <h1>About KKDIK</h1>
                                            </div>
                                        </div>
                                        <div className="card-body" style={{ fontSize: "1.3rem", textAlign: "justify" }}>
                                            <h2 className="text-black">Turkish REACH regulation</h2>

                                            <div className="d-flex justify-content-center m-2">
                                                <a
                                                    href="https://www.dgroute.com/en/kkdik.html"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        src={toAbsoluteUrl('/media/logos/kkdik-logo.png')}
                                                        title="KKDIK"
                                                        alt="KKDIK logo"
                                                    />
                                                </a>
                                            </div>

                                            <h3 className="text-black h2">Registration</h3>
                                            <p className="text-left">
                                                According to the <Link to="/kkdik">Turkish REACH</Link> regulation, Companies are <Link to="/services/only-representative-services">responsible</Link> for collecting information on the properties and uses of the substances they manufacture or import above one tonne a year. They also have to assess the hazards and potential risks presented by the substance.
                                            </p>

                                            <h3 className="text-black h2">Evaluation</h3>
                                            <p className="text-left" >
                                                Evaluation under KKDIK focuses on three different areas:

                                                Examination of testing proposals submitted by registrants
                                                Compliance check of the dossiers submitted by registrants
                                                Substance evaluation
                                            </p>

                                            <ul>
                                                <li>
                                                    <p>Examination of testing proposals submitted by registrants</p>
                                                </li>
                                                <li>
                                                    <p>Compliance check of the dossiers submitted by registrants</p>
                                                </li>
                                                <li>
                                                    <p>Substance evaluation</p>
                                                </li>
                                            </ul>

                                            <h2 className="text-black ">Authorisation</h2>
                                            <p className="text-left" >
                                                The authorisation process aims to ensure that substances of very high concern (SVHCs) are progressively replaced by less dangerous substances or technologies where technically and economically feasible alternatives are available.

                                                Substances with the following hazard properties may be identified as SVHCs:
                                            </p>

                                            <ul>
                                                <li>
                                                    <p>Substances meeting the criteria for classification as carcinogenic, mutagenic or toxic for reproduction (CMR) category 1A or 1B in accordance with the SEA Regulation.</p>
                                                </li>
                                                <li>
                                                    <p>Substances which are persistent, bioaccumulative and toxic (PBT) or very persistent and very bioaccumulative (vPvB) according to KKDIK Annex XIII.</p>
                                                </li>
                                                <li>
                                                    <p>Substances on a case-by-case basis, that cause an equivalent level of concern as CMR or PBT/vPvB substances.</p>
                                                </li>
                                            </ul>
                                            <p>
                                                The SVHC identification process includes a 45-day public consultation. Once a substance is identified as an SVHC, it is included in the Candidate List. The inclusion in the Candidate List brings immediate obligations for suppliers of the substance, such as:
                                            </p>
                                            <ul>
                                                <li>
                                                    <p>supplying a safety data sheet</p>
                                                </li>
                                                <li>
                                                    <p>communicating on safe use</p>
                                                </li>
                                                <li>
                                                    <p>responding to consumer requests within 45 days and</p>
                                                </li>
                                                <li>
                                                    <p>notifying MOE if the article they produce contains an SVHC in quantities above one tonne per producer/importer per year and if the substance is present in those articles above a concentration of 0.1% (w/w).</p>
                                                </li>
                                            </ul>

                                            <h3 className="text-black h2">Restriction</h3>
                                            <p className="text-left" >
                                                Restrictions are an instrument to protect human health and the environment from unacceptable risks posed by chemicals. Restrictions are normally used to limit or ban the manufacture, placing on the market (including imports) or use of a substance, but can impose any relevant condition, such as requiring technical measures or specific labels.
                                            </p>

                                            <h3 className="text-black h2">SCOPE</h3>
                                            <p className="text-left" >
                                                On 23 June 2017, the Ministry of Environment and Urbanization (MoEU) in Turkey published its REACH similar KKDIK regulation. The KKDIK regulation came into force on 23 Dec 2017. “KKDIK” are the first letters of REACH written in Turkish. Like REACH-EU regulation, the KKDIK regulation requires companies to register all substances manufactured in Turkey or imported into Turkey with volume above 1t/y before a given deadline.
                                                <br />
                                                KKDIK will bring various Turkish chemicals legislation under one law.
                                                <br />
                                                It will replace three existing laws:

                                            </p>

                                            <ul>
                                                <li>
                                                    <p>Regulation on the Inventory and Control of Chemicals;</p>
                                                </li>
                                                <li>
                                                    <p>Regulation on the Preparation and Distribution of Safety Datasheets for Hazardous Materials and Products; and</p>
                                                </li>
                                                <li>
                                                    <p>Regulation on the Restrictions Relating to the Production, Supply to the Market and Use of Certain Hazardous Materials, Products and Goods.</p>
                                                </li>
                                            </ul>
                                            <p>You can review the relevant regulation for legal reference.</p>

                                            <div className="d-flex justify-content-center m-2">
                                                <a
                                                    href="https://www.dgroute.com/en/kkdik.html"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        src={toAbsoluteUrl('/media/logos/kkdik-logo.png')}
                                                        title="KKDIK"
                                                        alt="KKDIK logo"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}