import React from "react";
import { toAbsoluteUrl } from '../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

export function Treach() {
    return (
        <>
            <Helmet>
                <title>KKDIK (T-REACH)</title>
                <meta name="description" content="KKDIK(T-REACH) is a regulation of Republic of Turkey, adopted from REACH-EU to improve the protection of human health and the environment from the risks that can be posed by chemicals, while enhancing the competitiveness of Turkish chemicals industry." />
            </Helmet>
            <div className="row contaier-fluid m-0 p-0">
                <div style={{ backgroundColor: "#F5F8FA" }}>
                    <div className='container'>
                        <div className="row my-5">
                            <div className="col-lg-12 mb-5">
                                <div className='rounded text-black'>
                                    <div className="card card-bordered p-10" style={{ backgroundColor: "#fff" }}>
                                        <div className="card-header ">
                                            <div className="card-title text-black">
                                                <h1>KKDIK(T-REACH) | Kkdik Services</h1>
                                            </div>
                                        </div>
                                        <div className="card-body" style={{ fontSize: "1.3rem", textAlign: "justify" }}>
                                            <p className="text-left">
                                                <Link to="/services/only-representative-services">KKDIK(T-REACH)</Link> is a regulation of Republic of Turkey, adopted from REACH-EU to improve the protection of human health and the environment from the risks that can be posed by chemicals, while enhancing the competitiveness of Turkish chemicals industry. It also promotes alternative methods for the hazard assessment of substances in order to reduce the number of tests on animals.

                                                In principle, <Link to="/kkdik">KKDIK(T-REACH)</Link> applies to all chemical substances; not only those used in industrial processes but also in our day-to-day lives, for example in cleaning products, paints as well as in articles such as clothes, furniture and electrical appliances. Therefore, the regulation has an impact on most companies across Turkey.

                                                KKDIK(T-REACH) places the burden of proof on companies. To comply with the regulation, companies must identify and manage the risks linked to the substances they manufacture and market in Turkey. They have to demonstrate to MoEU how the substance can be safely used, and they must communicate the risk management measures to the users. If the risks cannot be managed, authorities can restrict the use of substances in different ways. In the long run, the most hazardous substances should be substituted with less dangerous ones. KKDIK(T-REACH) stands for Registration, Evaluation, Authorisation and Restriction of Chemicals. It entered into force on 23 June 2017.
                                            </p>

                                            <h2 className="text-black">How does KKDIK work?</h2>
                                            <p className="text-left" >
                                                KKDIK(T-REACH) establishes procedures for collecting and assessing information on the properties and hazards of substances. Companies need to register their substances and to do this they need to work together with other companies who are registering the same substance. MoEU will receive and evaluate individual registrations for their compliance and evaluate selected substances to clarify initial concerns for human health or for the environment between 1 January 2021-31 December 2023. Turkey MoEU’s scientific committees assess whether the risks of substances can be managed. Authorities can ban hazardous substances if their risks are unmanageable. They can also decide to restrict a use or make it subject to a prior authorisation.
                                            </p>

                                            <h2 className="text-black">KKDIK’s effect on companies</h2>
                                            <p className="text-left" >
                                                KKDIK(T-REACH) impacts on a wide range of companies across many sectors, even those who may not think of themselves as being involved with chemicals. In general, under KKDIK(T-REACH) you may have one of these roles:
                                                <br /><br />
                                                <b>Manufacturer:</b> If you make chemicals, either to use yourself or to supply to other people (even if it is for export), then you will probably have some important responsibilities under KKDIK(T-REACH) .
                                                <br /><br />
                                                <b>Importer:</b> If you buy anything from outside Turkey, you are likely to have some responsibilities under KKDIK(T-REACH) . It may be individual chemicals, mixtures for onwards sale or finished products, like clothes, furniture or plastic goods.
                                                <br /><br />
                                                <b>Downstream users:</b> Most companies use chemicals, sometimes even without realising it, therefore you need to check your obligations if you handle any chemicals in your industrial or professional activity. You might have some responsibilities under KKDIK(T-REACH).
                                                <br /><br />
                                                <b>Companies established outside Turkey:</b> If you are a company established outside Turkey, you are not bound by the obligations of KKDIK(T-REACH) , even if you export their products into the customs territory of Turkey. The responsibility for fulfilling the requirements of KKDİK, such as registration lies with the importers established in Turkey, or with the only representative of a non-Turkish manufacturer established in Turkey.
                                            </p>

                                            <div className="d-flex justify-content-center m-2">
                                                <a
                                                    href="https://www.dgroute.com/en/kkdik.html"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        src={toAbsoluteUrl('/media/logos/kkdik-logo.png')}
                                                        alt="kkdiklogo"
                                                    />
                                                </a>
                                            </div>

                                            <p className="text-left text-primary h3" >
                                                <Link to="/contact">Do not hesitate to contact us for comprehensive information and offer about Only Representative Turkey and Turkish REACH Regulations.</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}