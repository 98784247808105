import React, { FC } from 'react'

const DemosToggleDrawer: FC = () => {

    const dFlex = {
        backgroundColor: "#FF672B",
        display: "flex"
    }

    const dNone = {
        backgroundColor: "#FF672B",
        display: "none"
    }

    if (window.location.pathname === "/kkdik") {
        var style;
        style = dNone
    } else {
        style = dFlex
    }
    return (
        <button
            id="kt_drawer_chat_toggle"
            className="engage-demos-toggle btn text-white btn-flex h-50px shadow-sm fs-6 px-4 rounded-top-0"
            style={style}
        >

            <span>
                <b>Contact Us</b>
            </span>
        </button>
    )
}

export { DemosToggleDrawer }
