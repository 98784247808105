// import React, { useState } from 'react'
// import TopBarProgress from 'react-topbar-progress-indicator'
// import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
// import { MenuTestPage } from '../pages/MenuTestPage'
// import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { Route, Routes, Navigate } from 'react-router-dom'
import { Home } from "../pages/Home"
import { About } from "../pages/About"
import { Services } from "../pages/Services"
import { Spage1 } from "../pages/Spage1"
import { Spage2 } from "../pages/Spage2"
import { Spage3 } from "../pages/Spage3"
import { Spage4 } from "../pages/Spage4"
import { Kkdik } from "../pages/Kkdik"
import { Treach } from "../pages/Treach"
import { Tpage1 } from "../pages/Tpage1"
import { Tpage2 } from "../pages/Tpage2"
import { Contact } from "../pages/Contact"

const PrivateRoutes = () => {
  // const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        <Route path="/services/only-representative-services" element={<Spage1 />} />
        <Route path="/services/sief-management" element={<Spage2 />} />
        <Route path="/services/pre-registration-services" element={<Spage3 />} />
        <Route path="/services/sds-authoring-service" element={<Spage4 />} />
        <Route path="/treach" element={<Treach />} />
        <Route path="/treach/about-kkdik" element={<Tpage1 />} />
        <Route path="/treach/what-is-kkdik-or" element={<Tpage2 />} />
        <Route path="/kkdik" element={<Kkdik />} />
        {window.location.href === "http://localhost:44478/rex" &&
          <Route path='*' element={<Navigate to="/about" />} />
        }
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export { PrivateRoutes }
