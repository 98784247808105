import React from "react";
import { toAbsoluteUrl } from '../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

export function Spage3() {
    return (
        <>
            <Helmet>
                <title>KKDIK Pre-Registration Services</title>
                <meta name="description" content="On June 23, 2017 KKDIK – Turkish REACH regulation was published by the Turkish Republic Ministry of Environment in the Official Gazette No. 30105 and came into force on December 23, 2017." />
            </Helmet>

            <div className="row contaier-fluid m-0 p-0">
                <div style={{ backgroundColor: "#F5F8FA" }}>
                    <div className='container'>
                        <div className="row my-5">
                            <div className="col-lg-12 mb-5">
                                <div className='rounded text-black'>
                                    <div className="card card-bordered p-10" style={{ backgroundColor: "#fff" }}>
                                        <div className="card-header ">
                                            <div className="card-title text-black">
                                                <h1>KKDIK Pre-Registration Services</h1>
                                            </div>
                                        </div>
                                        <div className="card-body" style={{ fontSize: "1.3rem", textAlign: "justify" }}>
                                            <p className="text-left">
                                                On June 23, 2017 <Link to="/kkdik">KKDIK</Link> – <Link to="/Treach/about-kkdik">Turkish REACH regulation</Link> was published by the  Turkish Republic Ministry of Environment in the Official Gazette No. 30105 and came into force on December 23, 2017.​ KKDIK Pre-Registration Service
                                                <br /><br />
                                                KKDIK has been prepared on the basis of EU REACH Regulation and adapted to the Turkish Industry within the frame of compliance with the legislation of the European Union.
                                                <br /><br />
                                                Registration, Restriction and Permission are similar processes within the scope of  KKDIK – Turkish REACH.
                                                <br /><br />
                                                Registration within the scope of KKDIK are divided into two process named as Pre-Registration and Registration
                                                <br /><br />
                                                European REACH registration of substances shall not be applied in Turkey. Therefore, these substances should be registered under the KKDIK again.
                                                <br /><br />
                                                The first step, the KKDIK Pre-Registration Process, will be held between 23 December 2017 to 31 December 2020. After the pre-registration process will be completed on 31 December 2020, the registration process will start.
                                                <br /><br />
                                                We can brief critical dates for these processes as below;
                                            </p>
                                            <br />
                                            <ul>
                                                <li>
                                                    <p><b>23 December 2017- 31 December 2020:</b> Pre-Registration will be held for the substances which are evaluated under KKDIK.</p>
                                                </li>
                                                <li>
                                                    <p><b>01 January 2021 – Beyond:</b> The main registration process will start for the substances evaluated under KKDIK. However, there are important dates that should be specified for this period. </p>
                                                </li>
                                                <li>
                                                    <p><b>01 January 2021 – 31 December 2023:</b> The registration process for substances evaluated under KKDIK will start. However, as the registration process continues, pre-registered substances can be produced or imported between these dates. Substances that are not previously registered may be produced or imported in the event of completion of the registration process.</p>
                                                </li>
                                                <li>
                                                    <p><b>01 January 2024 – Beyond:</b> The substances within the scope of KKDIK can be produced or imported on the condition that the registration process is completed from 01 January 2024.</p>
                                                </li>
                                                <li>
                                                    <p>The restriction provisions came into force on 23 December 2017. Other provisions shall be effective as from this date. Furthermore, all restrictions shall be effective on 31 December 2023.</p>
                                                </li>
                                            </ul>
                                            <br />
                                            <h2 className="text-black ">KKDIK Registrations:</h2>
                                            <p className="text-left">
                                                In order to prepare for the registration process, the process defined as the pre-registration process is called the pre-Substance Information Exchange forum (pre-SIEF) in the KKDIK regulation.
                                                <br /><br />
                                                Some of the relevant articles for Pre-Registration, that are mentioned as Pre-Substance Information Exchange Forum (pre-SIEF) in the KKDIK Regulation, are specified as follows;
                                                <br /><br />
                                                TRANSITIONAL ARTICLE 1- (1) All registrants, shall send a pre-SIEF including below mentioned information to the Ministry through Chemicals Registration System in the website of Ministry until 31/12/2020:
                                                a) Substance identity according to Annex-6;
                                                b) Role in the supply chain
                                                <br /><br />
                                                Entry into Force
                                                <br /><br />
                                                ARTICLE 66- (1) ç) All other provisions will enter into force 6 months later the date of its publication on the Official Gazette.
                                                As stated in the KKDIK Regulation, it is obligatory to submit Pre-SIEF (Pre-Registration) for chemical substances with registration obligation under the scope of KKDIK. This obligation comes into force as of December 23, 2017. The deadline for submission of the Pre-SIEF to the Ministry’s assessment is 31 December 2020.
                                                <br /><br />
                                                There is a Pre-Registration / Registration obligations for substances manufactured or imported in 1 ton or more per year in its own form or in the mixture.
                                            </p>

                                            <div className="d-flex justify-content-center m-2">
                                                <a
                                                    href="https://www.dgroute.com/en/kkdik.html"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        src={toAbsoluteUrl('/media/logos/kkdik-logo.png')}
                                                        title="KKDIK"
                                                        alt="KKDIK logo"
                                                    />
                                                </a>
                                            </div>

                                            <p className="text-left text-primary h3" >
                                                <Link to="/contact">Do not hesitate to contact us for comprehensive information and offer about KKDIK Pre-Registration Service and Turkish REACH Regulations.</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}