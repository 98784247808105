import React, { useState } from "react";
import { Link } from 'react-router-dom'
import { useKeenSlider, KeenSliderPlugin } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import "./styles.css"
import video from "./media/video.webm"
import { toAbsoluteUrl } from '../../_metronic/helpers'
import background1 from "./media/background1.webp"
import background3 from "./media/background3.webp"
import logokare from "./media/logo-kare.jpg"
import { Helmet } from "react-helmet";

const AdaptiveHeight: KeenSliderPlugin = (slider) => {
    function updateHeight() {
        slider.container.style.height =
            slider.slides[slider.track.details.rel].offsetHeight + "px"
    }
    slider.on("created", updateHeight)
    slider.on("slideChanged", updateHeight)
}

export function Home() {
    const [currentSlide, setCurrentSlide] = React.useState(0)
    const [loaded, setLoaded] = useState(false)
    const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
        {
            defaultAnimation: {
                duration: 1000,
                easing: (t: number) => t
            },
            renderMode: "performance",
            rubberband: false,
            loop: true,
            initial: 0,
            slideChanged(s) {
                setCurrentSlide(s.track.details.rel)
            },
            created() {
                setLoaded(true)
            },
            range: {
                min: 0,
                align: true
            },
        },
        [
            AdaptiveHeight,
            (slider) => {
                var vid = document.querySelector("video")
                let timeout: ReturnType<typeof setTimeout>
                let mouseOver = false
                var clearNextTimeout = () => {
                    clearTimeout(timeout)
                }
                var nextTimeout = () => {
                    clearTimeout(timeout)
                    if (mouseOver) return
                    timeout = setTimeout(() => {
                        slider.next()
                    }, 100000000000000000)
                }
                slider.on("created", () => {
                    slider.container.addEventListener("mouseover", () => {
                        mouseOver = true
                        clearNextTimeout()
                    })
                    slider.container.addEventListener("mouseout", () => {
                        mouseOver = false
                        nextTimeout()
                    })
                    nextTimeout()
                })
                slider.on("dragStarted", () => {
                    clearNextTimeout()
                })
                slider.on("updated", nextTimeout)
                slider.on("animationStarted", () => {
                    vid?.pause()
                })
                slider.on("animationEnded", () => {
                    nextTimeout();

                    if (slider.track.details.rel === 0) {
                        vid?.play()
                    }
                })
                window.addEventListener("scroll", () => {
                    if (window.scrollY > 50) {
                        vid?.pause()
                    } else {
                        vid?.play()
                    }
                })
            },
        ]
    )

    return (
        <div className="row container-fluid m-0 p-0">
            <Helmet>
                <title>Home | Kkdik Services</title>
                <meta name="description" content="KKDIK requires all companies manufacturing or placing a substance on Turkish market in quantities greater than 1t/year to register that substance with the MoEU." />
            </Helmet>

            <div className="p-0 m-0" style={{ backgroundColor: "#eff4f7" }}>
                <div className="navigation-wrapper">
                    <div ref={sliderRef} className="keen-slider">
                        <div className="keen-slider__slide">
                            <video style={{ minWidth: "100%", minHeight: "80vh" }} src={video} title="Green road" autoPlay muted loop></video>


                            <div className="innertext p-10" style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}>
                                <div>
                                    <h1 className="text-white display-4">T-REACH</h1>
                                </div>
                                <div>
                                    <p className="text-white text-left h2">Since 2013, Turkey harmonized major chemical regulations including CLP [OG 11.12.2013 – 28848] and REACH [OG 13.12.2014 – 29204 & OG 23.06.2017 – 30105] in effort to align its chemical regulatory status with the European Union (EU).</p>
                                </div>
                            </div>
                        </div>

                        <div className="keen-slider__slide">
                            <img style={{ minWidth: "100%", minHeight: "80vh" }} src={background1} alt="Ship in port" title="Ship" />

                            <div className="innertext p-12" style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}>
                                <div>
                                    <p className="text-white text-left h2">To place a product on the Turkish market, companies are now required to comply with an extensive list of legal obligations mainly pertaining to classification, labelling and packaging of chemicals. Managing your compliance needs in this evolving regulatory landscape might prove a complicated task and that’s where we come in.</p>
                                </div>
                            </div>
                        </div>

                        <div className="keen-slider__slide">
                            <img style={{ minWidth: "100%", minHeight: "80vh" }} src={background3} alt="Factory and clouds" title="Factory" />
                        </div>
                    </div>

                    {loaded && instanceRef.current && (
                        <>
                            <Arrow
                                left
                                onClick={(e: any) =>
                                    e.stopPropagation() || instanceRef.current?.prev()
                                }
                                disabled={currentSlide === 0}
                            />

                            <Arrow
                                onClick={(e: any) =>
                                    e.stopPropagation() || instanceRef.current?.next()
                                }
                                disabled={
                                    currentSlide ===
                                    instanceRef.current.track.details.slides.length - 1
                                }
                            />
                        </>
                    )}
                </div>

                {loaded && instanceRef.current && (
                    <div className="dots">
                        {[
                            ...Array(instanceRef.current.track.details.slides.length).keys(),
                        ].map((idx) => {
                            return (
                                <div
                                    key={idx}
                                    onClick={() => {
                                        instanceRef.current?.moveToIdx(idx)
                                    }}
                                    className={"dot" + (currentSlide === idx ? " active" : "")}
                                ></div>
                            )
                        })}
                    </div>
                )}

                {/* <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mb-5">
                        </div>
                        <div className="col lg 12 my-5" >
                            <div className='rounded mb-5 text-black'>
                                
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>

            <div style={{ backgroundColor: "#fff" }}>
                <div className="container">
                    <div className="row justify-content-center mt-5">
                        <div className="col-lg-12">
                            <div className='rounded mb-5'>
                                <div className="card p-10" style={{ backgroundColor: "#eff4f7" }}>
                                    <div className="card-header">
                                        <div className="card-title" >
                                            <h2 className="text-black h1">T-REACH (KKDIK) OR Service</h2>
                                        </div>
                                    </div>
                                    <div className="card-body" style={{ color: "black", fontSize: "1.4rem", textAlign: "justify" }}>
                                        <p>KKDIK requires all companies manufacturing or placing a substance on Turkish market in quantities greater than 1t/year to register that substance with the MoEU. For legal reasons, only companies with a legal entity in Turkey are allowed to submit a registration; however, non-TR companies may submit registration by appointing a TR-based Only Representative to register on their behalf, in which case their importers will be regarded as downstream users and do not need to do registrations. Benefits of Appointing REACH Only Representative (OR) Relieve importers of their obligations to register and obtain continued market access in Turkey (many TR importers will try to avoid registrations by purchasing KKDIK registered chemicals); Avoid dependence on a single importer and keep market access should one TR importer cease trading; Gain advantages over other non-TR suppliers who do not appoint OR to register their substances; Note: Importers will be exempt from KKDIK registration if their non-TR suppliers have registered; however, importers need to confirm with their suppliers' Only Representative that they are included in the inventory of importers and their tonnage and uses are covered by the OR. This can be done by asking for KKDIK Certificate of Compliance and Tonnage Coverage Certificate from the only representative of their suppliers before they put chemicals on the TR market.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ backgroundColor: "#eff4f7" }}>
                <div className="container">
                    <div className="row justify-content-around align-items-center my-5" >
                        <div className="col-md-3 col-sm-4 col-8 mb-5" >
                            <div className="card card-custom overlay overflow-hidden">
                                <div className="card-body p-10">
                                    <div className="overlay-wrapper text-center">
                                        <img title="Contact arrow" alt="Arrow" src={toAbsoluteUrl("/media/icons/duotune/general/gen016.svg")} style={{ height: "8rem", width: "8rem" }} />
                                        <p className="text-center h5"><b>Contact Us</b></p>
                                    </div>
                                    <div className="overlay-layer bg-primary bg-opacity-10 align-items justify-content-center">
                                        <div className="d-flex flex-grow-1 flex-center py-5">
                                            <Link
                                                to="/contact"
                                                rel="noreferrer"
                                                style={{ color: "white", backgroundColor: "#FF672B" }}
                                                className="justify-content-center btn btn-sm btn-shadow ms-2"
                                            >
                                                <b>Contact Us</b>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-4 col-8 mb-5">
                            <div className="card card-custom overlay overflow-hidden">
                                <div className="card-body p-10">
                                    <div className="overlay-wrapper">
                                        <img src={logokare} title="Company logo" alt="Logo square" className="w-100" />
                                    </div>
                                    <div className="overlay-layer bg-primary bg-opacity-10 align-items justify-content-center">
                                        <div className="d-flex flex-grow-1 flex-center py-5">
                                            <a
                                                href="https://www.dgroute.com/"
                                                target="_blank"
                                                style={{ color: "white", backgroundColor: "#FF672B" }}
                                                rel="noreferrer"
                                                className="btn btn-sm btn-dark btn-shadow ms-2"
                                            >
                                                <b>Visit Us!</b>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-4 col-8 mb-5">
                            <div className="card card-custom overlay overflow-hidden">
                                <div className="card-body p-10">
                                    <div className="overlay-wrapper text-center">
                                        <img src={toAbsoluteUrl("/media/icons/duotune/general/gen004.svg")} title="Magnifying" alt="Magnifying glass" style={{ height: "8rem", width: "8rem" }} />
                                        <p className="text-center h5"><b>Explore KKDİK</b></p>
                                    </div>
                                    <div className="overlay-layer bg-primary bg-opacity-10 align-items justify-content-center">
                                        <div className="d-flex flex-grow-1 flex-center py-5">
                                            <Link
                                                to="/kkdik"
                                                rel="noreferrer"
                                                style={{ color: "white", backgroundColor: "#FF672B" }}
                                                className="btn btn-sm btn-shadow ms-2"
                                            >
                                                <b className="text-center">Explore KKDİK</b>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function Arrow(props: {
    disabled: boolean
    left?: boolean
    onClick: (e: any) => void
}) {
    const disabeld = props.disabled ? " arrow--disabled" : ""
    return (
        <svg
            onClick={props.onClick}
            className={`arrow ${props.left ? "arrow--left" : "arrow--right"
                } ${disabeld}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            {props.left && (
                <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
            )}
            {!props.left && (
                <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
            )}
        </svg>
    )
}
