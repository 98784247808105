/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Link, Routes, Outlet } from 'react-router-dom'
import { Error500 } from './components/Error500'
import { Error404 } from './components/Error404'
import kkdiklogo from '../../pages/media/kkdik-logo.png'

const ErrorsLayout = () => {
  return (
    <div className='d-flex flex-column flex-root' style={{ backgroundColor: "#0E7D95" }}>
      <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
        <div className='d-flex flex-column flex-column-fluid text-center p-10 py-lg-20'>
          <Link to='/' className='mb-10 pt-lg-20'>
            <img
              alt='Logo'
              src={kkdiklogo}
              className='h-50px mb-5'
            />
          </Link>
          <div className='pt-lg-10 mb-10'>
            <Outlet />
            <div className='text-center'>
              <Link to='/' className='btn btn-lg fw-bolder' style={{ backgroundColor: "#36404B", color: "#fff" }}>
                Go to homepage
              </Link>
            </div>
          </div>
        </div>

        <div className='d-flex flex-center flex-column-auto p-10'>
          <div className='d-flex align-items-center fw-bold fs-6'>
            <Link to='/about' className='text-hover-primary px-2' style={{ color: "#fff" }}>
              About
            </Link>
            <Link to='/contact' className='text-hover-primary px-2' style={{ color: "#fff" }}>
              Contact
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

const ErrorsPage = () => (
  <Routes>
    <Route element={<ErrorsLayout />}>
      <Route path='404' element={<Error404 />} />
      <Route path='500' element={<Error500 />} />
      <Route index element={<Error404 />} />
    </Route>
  </Routes>
)

export { ErrorsPage }
